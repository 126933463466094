<template>
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9997 29.0599C8.7997 29.0599 2.93848 23.1987 2.93848 15.9987C2.93848 8.79872 8.7997 2.9375 15.9997 2.9375C23.1997 2.9375 29.0609 8.79872 29.0609 15.9987C29.0609 23.1987 23.1997 29.0599 15.9997 29.0599Z"
      fill="#AA68F0"
    />
    <path
      d="M14.3676 19.9198C14.2043 19.9198 14.0411 19.8545 13.9104 19.7239L10.6451 16.4586C10.3839 16.1973 10.3839 15.7892 10.6451 15.5279C10.9064 15.2667 11.3145 15.2667 11.5757 15.5279L14.8411 18.7933C15.1023 19.0545 15.1023 19.4626 14.8411 19.7239C14.6941 19.8545 14.5309 19.9198 14.3676 19.9198Z"
      fill="white"
    />
    <path
      d="M14.3669 19.9195C14.2037 19.9195 14.0404 19.8542 13.9098 19.7235C13.6486 19.4623 13.6486 19.0542 13.9098 18.7929L20.4404 12.2623C20.7016 12.0011 21.1098 12.0011 21.371 12.2623C21.6322 12.5235 21.6322 12.9317 21.371 13.1929L14.8404 19.7235C14.6935 19.8542 14.5302 19.9195 14.3669 19.9195Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {
  name: 'CompletedNotification',
};
</script>

<style scoped lang="scss"></style>
