import { render, staticRenderFns } from "./CompletedNotification.vue?vue&type=template&id=97ce0442&scoped=true&"
import script from "./CompletedNotification.vue?vue&type=script&lang=js&"
export * from "./CompletedNotification.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ce0442",
  null
  
)

export default component.exports