<template>
  <div>
    <Modal modalWidth="430px" @close-modal="setShow(false)">
      <div class="wallect" :class="themetype === true ? 'wallect-dark' : ''">
        <div class="ellipse" />
        <img
          class="vector-grid"
          alt=""
          src="../../../assets/image/icon/new/grid.svg"
          v-if="!themetype"
        />
        <img
          class="vector-grid"
          alt=""
          src="../../../assets/image/icon/new/grid-dark.svg"
          v-else
        />
        <template slot="closeIcon">
          <div class="close-icon-wrapper">
            <img
              src="../../../assets/image/dark/Close_L3.svg"
              v-if="themetype"
            />
            <img src="../../../assets/image/icon/Close_L.svg" v-else />
          </div>
        </template>
        <div class="t1">
          <span class="connect-logo-wrapper">
            <img src="../../../assets/image/icon/new/link.svg" />
          </span>
          Connect Wallets
        </div>
        <div class="t2">To start using cedro</div>
        <div class="wallect-list">
          <div
            class="d1"
            v-for="(item, index) in list"
            :key="index"
            @click="sigin(item.name)"
          >
            <div class="wallet-logo-wrapper">
              <img :src="item.wallect" />
            </div>
            <div>{{ item.text }}</div>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { connect } from '../../../SDK';
import { mapGetters } from 'vuex';
import Modal from '../../../components/new/Modal/index';

export default {
  name: 'wallect',
  props: {
    setShow: Function,
  },
  components: {
    Modal,
  },
  computed: {
    ...mapGetters(['themetype']),
  },
  data() {
    return {
      list: [
        {
          wallect: require('../../../assets/image/wallect/new/metamask.svg'),
          text: 'Metamask',
          name: 'injected',
        },
        {
          wallect: require('../../../assets/image/wallect/new/wallet-connect.svg'),
          text: 'Wallet Connect ',
          name: 'walletconnect',
        },
        {
          wallect: require('../../../assets/image/wallect/new/coinbase.svg'),
          text: 'Coinbase Wallet',
          name: 'walletlink',
        },
        {
          wallect: require('../../../assets/image/wallect/new/binance.svg'),
          text: 'Binance Chain Wallet',
          name: 'portis',
        },
        // TODO add connect implementation for pontem
        {
          wallect: require('../../../assets/image/wallect/new/pontem.svg'),
          text: 'Pontem Aptos Wallet',
          name: 'pontem',
        },
      ],
      bg: '#ffffff',
    };
  },
  watch: {},
  created() {
    this.themetypeF();
  },
  methods: {
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
      } else {
        this.bg = `#ffffff`;
      }
    },
    closemodel() {
      if (this.setShow) {
        this.setShow(false);
      }
    },
    sigin(index) {
      connect(index, (res) => {
        this.account = res.account;
        this.$store.dispatch('user/setAccount', res.account);
        this.$store.dispatch('user/setChain', res.chain);
        this.$store.dispatch('user/setChainid', res.chainID);
        localStorage.setItem('account', res.account);
      }).then((data) => {
        this.$store.dispatch('user/setAccount', data.account);
        this.$store.dispatch('user/setChain', data.chain);
        this.$store.dispatch('user/setChainid', data.chainID);
        this.$store.dispatch('user/setproviderInfo', data.providerInfo);
        localStorage.setItem('wallet', data.providerInfo.id);
        localStorage.setItem('account', data.account);
      });
      this.closemodel();
    },
  },
};
</script>

<style scoped lang="scss">
.wallect {
  position: relative;
  // width: 340px;
  .close-icon-wrapper {
    cursor: pointer;
    background: #ffffff;
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 18px;
    margin-top: 18px;
  }
  .ellipse {
    position: absolute;
    margin: 0 !important;
    top: -100px;
    left: calc(50% - 100px);
    border-radius: 50%;
    background-color: #b7b8ff;
    filter: blur(200px);
    width: 200px;
    height: 200px;
    z-index: 0;
  }

  .vector-grid {
    position: absolute;
    margin: 0 !important;
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
    width: 331.48px;
    height: 237.75px;
    mix-blend-mode: lighten;
    z-index: 1;
  }

  .t1 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33.8px;
    text-align: center;
    color: #141414;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 17px;
    position: relative;
    z-index: 3;

    .connect-logo-wrapper {
      cursor: pointer;
      background: linear-gradient(212deg, #d093ff 0%, #c8b7ff 100%);
      position: relative;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      z-index: 5;
    }
  }
  .t2 {
    font-family: 'Satoshi';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
    padding: 14px 0 36px;
    position: relative;
    z-index: 5;
  }
  .wallect-list {
    margin-top: 11px;
    position: relative;
    z-index: 5;

    .d1 {
      height: 66px;
      cursor: pointer;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      background: #ffffff;
      border-radius: 20px;
      margin-bottom: 8px;
      padding: 6px;

      div {
        font-family: 'Satoshi';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #141414;
        letter-spacing: -0.18px;
      }
      img {
        margin-right: 18px;
      }
    }
    .d1:hover {
      background: rgba(170, 104, 240, 0.12);
    }
  }
}
.wallect-dark {
  .ellipse {
    background-color: #35119c;
  }
  .t1 {
    color: #e9eaf2;
  }
  .t2 {
    color: #abacb3;
  }
  .wallect-list {
    .d1 {
      background: #0b0b0b;
      div {
        color: #e9eaf2;
      }
    }
    .d1:hover {
      background: rgba(170, 104, 240, 0.12);
      div {
        color: #aa68f0;
      }
    }
  }
}
</style>
