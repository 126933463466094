<template>
  <div @click="toggle" class="dropdown-toggler">
    <slot name="toggler">
      <button>Toggle</button>
    </slot>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Dropdown',
  mounted() {
    document.addEventListener('click', this.closeDropdown);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.closeDropdown);
  },
  provide() {
    return {
      sharedState: this.sharedState,
    };
  },
  data() {
    return {
      sharedState: {
        active: false,
      },
    };
  },
  methods: {
    toggle() {
      this.sharedState.active = !this.sharedState.active;
    },
    away() {
      this.sharedState.active = false;
    },
    closeDropdown(event) {
      if (this.sharedState.active) {
        if (!this.$el.contains(event.target)) {
          this.toggle();
        }
      }
    },
  },
};
</script>

<style scoped>
.dropdown-toggler {
  position: relative;
  display: inline-block;
}
</style>
```
