import Vue from 'vue';
import Router from 'vue-router';
import Layout from '../Layout/index.vue';

Vue.use(Router);

const routes = [
  {
    path: '/',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: '/Dashboard',
        name: 'Dashboard',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/Dashboard/index.vue'
          ),
        meta: { keepAlive: false },
      },

      {
        path: '/Market',
        name: 'Market',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/Market/index.vue'
          ),
        meta: { keepAlive: true },
      },
      {
        path: '/Market/MarketDetails',
        name: 'MarketDetails',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Market/components/MarketDetails.vue'
          ),
      },
      {
        path: '/Market/History',
        name: 'History',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/TxnHistory/index.vue'
          ),
      },
      {
        path: '/Liquidations',
        name: 'Liquidations',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/Liquidations/index.vue'
          ),
      },
      {
        path: '/Design/Dashboard',
        name: 'DesignDashboard',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/Dashboard/index.vue'
          ),
      },
      {
        path: '/Design/Market',
        name: 'DesignMarket',
        component: () =>
          import(
            /* webpackChunkName: "home" */ '../views/Design/Market/index.vue'
          ),
      },
    ],
  },
];

const router = new Router({
  // mode: "history",
  base: '/Dashboard',
  scrollBehavior: () => ({ y: 0 }),
  routes,
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach(async (to, from, next) => {
  // determine whether the user has logged in
  // const hasToken = getToken()
  if (to.path == '/') {
    next({ path: '/Dashboard' });
  } else {
    next();
  }
});
export default router;
