<template>
  <div class="app-wrapper" :class="{ dark: themetype }">
    <header>
      <navigation />
    </header>
    <main class="bodys">
      <notice v-if="false"></notice>
      <router-view />
    </main>
  </div>
</template>

<script>
import notice from '../components/notice';
// import navigation from '../components/navigation/index.vue';
import navigation from '../components/new/navigation/index.vue';
import { mapGetters } from 'vuex';
import { getTxh, getUserInfo } from '../SDK';
import CompletedNotification from '../components/CompletedNotification';
import CompletedNotificationclose from '../components/CompletedNotificationclose';
import '../style/notification-light.css';
export default {
  name: 'index',
  computed: {
    ...mapGetters([
      'isLogin',
      'networkF',
      'iswidth',
      'account',
      'chain',
      'chainid',
      'themetype',
    ]),
  },
  data() {
    return {
      getInfoT: null,
      txnnumber: 0,
    };
  },
  destroyed() {
    clearInterval(this.getInfoT);
  },
  watch: {
    account(val) {
      let Ischain = this.Reactchain(this.chainid);
      if (val !== '' && Ischain) {
        getUserInfo().then((ui) => {
          this.$store.dispatch('user/setBorroeInfo', ui);
        });
      }
    },
  },
  methods: {
    openNotification() {
      this.$notification.open({
        class: 'text',
        style: { backgroundColor: 'var(--Notificationbg)' },
        duration: 3,
        message: (
          <div style="display: flex; justify-content: center;align-items: center;">
            Transaction completed{' '}
          </div>
        ),
        description: '',
        icon: <CompletedNotification />,
        closeIcon: <CompletedNotificationclose />,
      });
    },
    Reactchain(num) {
      let res = false;
      switch (num) {
        case 5:
          res = true;
          break;
        case 80001:
          res = true;
          break;
        case 421613:
          res = true;
          break;
        case 97:
          res = true;
          break;
        case 43113:
          res = true;
          break;
        case 4002:
          res = true;
          break;
        default:
          res = false;
      }
      return res;
    },
    info() {
      setTimeout(() => {
        console.log('1');
        let Ischain = this.Reactchain(this.chainid);
        console.log('2', Ischain);
        if (this.account !== '' && Ischain) {
          getUserInfo().then((ui) => {
            console.log('3', ui);
            this.$store.dispatch('user/setBorroeInfo', ui);
          });
          console.log('4');
          getTxh().then((datatxh) => {
            let a = datatxh.filter((im) => im.status === 'Pending');

            if (a.length > 0) {
              this.$store.dispatch('user/settxntype', 1);
            } else {
              this.$store.dispatch('user/settxntype', 2);
            }
            if (this.txnnumber > a.length) {
              for (let i = 0; i < this.txnnumber - a.length; i++) {
                this.openNotification();
              }
            }
            this.txnnumber = a.length;
          });
          console.log('5');
        } else {
          this.$store.dispatch('user/setBorroeInfo', null);
          console.log('6');
        }
        this.info();
        console.log('7');
      }, 20 * 1000);
    },
    infoTXN() {
      setTimeout(() => {
        let Ischain = this.Reactchain(this.chainid);
        if (this.account !== '' && Ischain) {
          getTxh().then((datatxh) => {
            let a = datatxh.filter((im) => im.status === 'Pending');
            if (a.length > 0) {
              this.$store.dispatch('user/settxntype', 1);
            } else {
              this.$store.dispatch('user/settxntype', 2);
            }
            this.infoTXN();
          });
        } else {
          this.infoTXN();
        }
      }, 3 * 1000);
    },
  },
  mounted() {},

  created() {
    console.log('kaishi');
    this.info();
    this.infoTXN();
  },
  components: {
    navigation,
    notice,
  },
};
</script>

<style lang="scss">
#app {
  font-family: 'Satoshi';
  font-weight: 700;
  font-size: 15px;
  line-height: 100%;
  .app-wrapper {
    background: url('../assets/image/dashboard/bg-pattern.png'), #3729f8;
    background-size: cover;
    &.dark {
      background: url('../assets/image/dashboard/bg-pattern-dark.png'), #372c80;
      background-size: cover;
    }
  }
}
.bodys {
  position: relative;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  img {
    width: 50px;
    height: 50px;
  }
}
</style>
