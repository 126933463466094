<template>
  <transition name="dropdown-content">
    <div v-if="active" class="dropdown-bg" :class="{ dark: themetype }">
      <slot />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'DropdownContent',
  inject: ['sharedState'],
  computed: {
    ...mapGetters(['themetype']),
    active() {
      return this.sharedState.active;
    },
  },
};
</script>

<style lang="scss">
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: all 0.2s;
}
.dropdown-content-enter,
.dropdown-content-leave-to {
  opacity: 0;
  transform: translateY(-5px);
}
// .dropdown-bg {
//   position: absolute;
//   right: 0;
//   margin-top: 8px;
//   transform-origin: top right;
//   background-color: red;
//   border-radius: 0.5rem;
//   border-width: 1px;
//   padding: 8px 0;
// }
</style>
