<template>
  <div class="title-p" v-if="shownot">
    <div class="p">
      <div>
        Cedro will redeploy the smart contract on Dec 28 9:00 PM (PST), the Root
        Chain will be changed to BSC TestChain, and your old test data will be
        cleared. This will not affect our community programs.
      </div>
      <img src="../assets/image/icon/Close_L.svg" @click="show" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'notice',
  data() {
    return {
      shownot: true,
    };
  },
  methods: {
    show() {
      this.shownot = false;
    },
  },
};
</script>

<style scoped lang="scss">
.title-p {
  z-index: 77;
  width: 100%;
  position: fixed;
  background: #f1e6fe;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
  .p {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px;
    max-width: 1440px;
    width: 100%;
    img {
    }
    div {
      width: 85%;
      padding-left: 68px;
      font-family: 'GT America Trial Ext Rg';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #aa68f0;
    }
  }
}
@media only screen and (max-width: 750px) {
  .title-p {
    z-index: 77;
    width: 100%;
    position: fixed;
    background: #f1e6fe;
    padding-top: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .p {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px;
      //max-width: 1440px;
      width: 100%;
      img {
      }
      div {
        zoom: 0.8;
        padding-left: 24px;
        font-family: 'GT America Trial Ext Rg';
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 18px;
        color: #aa68f0;
      }
    }
  }
}
</style>
