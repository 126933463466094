<template>
  <button
    class="btn"
    :class="{
      primary: variant === 'primary',
      secondary: variant === 'secondary',
      dark: themetype,
      disabled: isDisabled || isLoading,
      sm: size === 'sm',
      md: size === 'md',
      lg: size === 'lg',
    }"
    @click.stop="handleClick"
    :disabled="isDisabled || isLoading"
  >
    <div class="btn-spinner" v-if="isLoading">
      <img src="../../../assets/image/icon/new/spinner.svg" />
    </div>
    <span>
      <slot />
    </span>
    <div class="btn-icon" v-if="showIcon">
      <img
        v-if="variant === 'primary' && !isDisabled"
        src="../../../assets/image/icon/new/directionRight-white.svg"
        class="primary direction-right"
        height="15px"
        width="15px"
      />
      <img
        v-if="variant === 'primary' && !isDisabled"
        src="../../../assets/image/icon/new/directionRight.svg"
        class="primary direction-right-hover"
        height="15px"
        width="15px"
      />
      <img
        v-if="variant === 'secondary' && !isDisabled"
        src="../../../assets/image/icon/new/directionRight.svg"
        class="direction-right"
        height="15px"
        width="15px"
      />
      <img
        v-if="isDisabled"
        src="../../../assets/image/icon/new/directionRight-disabled.svg"
        class="direction-right-disabled"
        height="15px"
        width="15px"
      />
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Button',
  props: {
    isDisabled: { default: false, type: Boolean },
    isLoading: { default: false, type: Boolean },
    showIcon: { default: false, type: Boolean },
    size: { default: 'md', type: String },
    variant: { default: 'primary', type: String },

    handleClick: {
      type: Function,
      default() {
        return () => true;
      },
    },
  },
  computed: {
    ...mapGetters(['themetype']),
  },
};
</script>

<style scoped lang="scss">
.btn {
  font-size: 15px;
  font-weight: 700;
  line-height: 100%;
  text-align: center;
  border-radius: 48px;
  border: none;
  display: flex;
  gap: 6px;
  align-items: center;
  transition: all 0.1s linear;
  padding: 14px 24px;
  cursor: pointer;
  height: fit-content;

  &.sm {
    padding: 10px 24px;
  }
  &.md {
    padding: 14px 24px;
  }
  &.lg {
    padding: 18px 24px;
  }

  &.primary {
    background: #141414;
    color: #ffffff;

    &:hover {
      background: #edfb8b;
      color: #141414;
    }

    &.disabled {
      background: #1414141a;
      color: #14141433;
      cursor: not-allowed;
    }
  }
  &.secondary {
    border: 2px solid #141414;
    color: #141414;
    background: none;

    &.disabled {
      border-color: #1414141a;
      color: #1414141a;
      cursor: not-allowed;
    }
  }
  &.dark {
    &.primary {
      background: #6751ff;
      color: #e9eaf2;

      &:hover {
        background: #cfe72a;
        color: #0b0b0b;
      }

      &.disabled {
        background: #4d4f56;
        color: #808187;
        cursor: not-allowed;
      }
    }
    &.secondary {
      border: 2px solid #393b42;
      color: #e9eaf2;

      &.disabled {
        border-color: #4d4f56;
        color: #808187;
        cursor: not-allowed;
      }
    }
  }
  .btn-icon {
    .primary {
      &.direction-right-hover {
        display: none;
      }
    }
  }

  &:hover {
    .btn-icon {
      .primary {
        &.direction-right {
          display: none;
        }
        &.direction-right-hover {
          display: block;
        }
      }
    }
  }
}
</style>
