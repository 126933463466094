<template>
  <div class="dropdown-item" @click="handleClick">
    <slot />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'DropdownItem',
  computed: {
    ...mapGetters([
      'account',
      'isLogin',
      'networkF',
      'iswidth',
      'providerInfo',
      'chain',
      'chainid',
      'themetype',
      'equipment',
      'TXNtype',
    ]),
  },
  props: {
    handleClick: Function,
  },
};
</script>

<style scoped lang="scss">
.dropdown-item {
  display: block;

  &:hover {
    background-color: #fff;
    border-radius: 12px;
  }
}
</style>
