<template>
  <a-popover :title="false" overlayClassName="pop-popover2">
    <template #content>
      <div class="token-popover" style="width: 261px">
        <div class="section">
          <div class="heading">Contract Address</div>
          <div class="value-wrapper">
            <p class="value">
              {{ itemId.slice(0, 6) + '...' + itemId.slice(-6) }}
            </p>
            <div
              @mouseenter="iconHighlight4 = false"
              style="cursor: pointer"
              @mouseleave="iconHighlight4 = true"
              v-clipboard:success="copSuccess"
              v-clipboard:copy="address"
            >
              <img
                src="../../../assets/image/dark/copy4.svg"
                class="img"
                v-if="iconHighlight4"
                height="16px"
                width="16px"
              />
              <img
                src="../../../assets/image/icon/copy3.svg"
                class="img"
                v-else
                height="16px"
                width="16px"
              />
            </div>
          </div>
        </div>
        <div class="section">
          <div class="heading">Symbol</div>
          <div class="value">
            {{ symbol }}
          </div>
        </div>
        <div class="section">
          <div class="heading">Precision</div>
          <div class="value">
            {{ decimals }}
          </div>
        </div>
      </div>
    </template>
    <div
      @mouseenter="iconHighlight1 = false"
      @mouseleave="iconHighlight1 = true"
      style="height: 16px; width: 16px; display: flex; justify-content: center"
      v-if="themetype"
    >
      <img
        src="../../../assets/image/dark/question3.svg"
        style="cursor: pointer; height: 100%; width: 100%"
        v-if="iconHighlight1"
      />
      <img
        src="../../../assets/image/icon/question2.svg"
        style="cursor: pointer; height: 100%; width: 100%"
        v-else
      />
    </div>
    <div
      @mouseenter="iconHighlight1 = false"
      @mouseleave="iconHighlight1 = true"
      style="height: 16px; width: 16px; display: flex; justify-content: center"
      v-else
    >
      <img
        src="../../../assets/image/icon/question.svg"
        style="cursor: pointer; height: 100%; width: 100%"
        v-if="iconHighlight1"
      />
      <img
        src="../../../assets/image/icon/question2.svg"
        style="cursor: pointer; height: 100%; width: 100%"
        v-else
      />
    </div>
  </a-popover>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'TokenPopover',
  computed: {
    ...mapGetters(['themetype']),
  },
  props: {
    itemId: {
      type: String,
      default: '',
    },
    symbol: {
      type: String,
      default: '',
    },
    decimals: {
      type: Number,
      default: 0,
    },
    address: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      iconHighlight1: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
    };
  },
  methods: {
    copSuccess() {
      this.$message.success('copy success');
    },
  },
};
</script>

<style scoped lang="scss">
.token-popover {
  padding-top: 16px;
  .section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;
    .heading {
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
      color: #ffffffcc;
    }
    .value-wrapper {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 12px;
    }
    .value {
      font-size: 12px;
      font-weight: 500;
      line-height: 140%;
      color: #ffffff;
      margin: 0;
    }
  }
}
</style>
