<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { getDeviceType } from './SDK';
import { mapGetters } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapGetters(['equipment']),
  },
  data() {
    return {
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
    };
  },
  created() {
    let _ethereum = window['ethereum'];
    if (!_ethereum || !_ethereum.isMetaMask) {
      window.open('https://metamask.io/download/');
    }
  },
  mounted() {
    let type = getDeviceType();
    if (type === 0) {
      this.$store.dispatch('user/setEquipment', 0);
    } else if (type === 1) {
      this.$store.dispatch('user/setEquipment', 1);
    }
  },
};
</script>

<style></style>
