<template>
  <nav :class="themetype === true ? 'top-dark' : 'top'">
    <img
      src="../../../assets/image/icon/new/logo.svg"
      class="nav-logo"
      @click="backloding()"
    />
    <ul class="nav-menu">
      <li
        v-for="item in navMenu"
        class="nav-item"
        :key="item.key"
        :class="{ 'nav-item-active': item.key === current[0] }"
      >
        <div @click="handleClick(item)" v-if="item.path !== ''">
          {{ item.name }}
        </div>
        <div @click="openDocs" v-if="item.name === 'Docs'">
          {{ item.name }}
          <img
            src="../../../assets/image/icon/new/export.svg"
            style="cursor: pointer"
            alt=""
          />
        </div>
      </li>
    </ul>
    <div class="nav-right">
      <div class="right-false" v-if="account == ''">
        <Button size="sm" :loading="loading" :handleClick="login">
          Connect Wallet
        </Button>
      </div>

      <div class="right" v-else>
        <Button size="sm" :loading="loading" :handleClick="token">
          Get Test Token
        </Button>

        <div class="switch-network">
          <Dropdown>
            <template slot="toggler">
              <button class="dropdown-toggler-btn">
                <img :src="icons(chain)" width="24px" height="24px" />
                <div>{{ chain === '' ? '' : chain }}</div>
                <img
                  src="../../../assets/image/icon/new/arrow-down-white.svg"
                  width="20px"
                  height="20px"
                />
              </button>
            </template>
            <DropdownContent>
              <DropdownItem
                v-for="(item, index) in networklist2"
                :key="index"
                :handleClick="() => SwitchNetworkF(item)"
              >
                <div class="switch-chain-list-item">
                  <img :src="item.icon" width="30px" height="30px" />
                  <div>{{ item.name }}</div>
                </div>
              </DropdownItem>
            </DropdownContent>
          </Dropdown>
        </div>

        <div class="wallet-info">
          <!-- TODO: fetch value dynamically -->
          <span class="wallet-balance">$150,390.57</span>
          <Dropdown>
            <template slot="toggler">
              <button class="dropdown-toggler-btn">
                <img
                  class="wallet-icon"
                  :src="providerInfo.logo"
                  width="15px"
                  height="15px"
                />
                <div class="right-token">
                  {{
                    account === ''
                      ? ''
                      : account.slice(0, 3) + '...' + account.slice(-4)
                  }}
                </div>
                <img
                  src="../../../assets/image/icon/new/arrow-down.svg"
                  v-if="!themetype"
                />
                <img
                  src="../../../assets/image/icon/new/arrow-down-white.svg"
                  v-else
                />
              </button>
            </template>
            <DropdownContent>
              <!-- TODO: fetch Token value dynamically -->

              <div class="available-tokens">
                <div class="n1">Available Tokens:</div>
                <div class="spinner" v-if="isTokenListLoading">
                  <img
                    src="../../../assets/image/icon/new/spinner.svg"
                    alt=""
                  />
                </div>
                <div class="token" v-for="token in tokenList" :key="token.id">
                  <div class="token-name">
                    <img
                      :src="icons(chain)"
                      width="30px"
                      height="30px"
                      alt=""
                    />
                    <div>{{ token.symbol }}</div>
                  </div>
                  <div class="token-balance">
                    <div class="token-amt">
                      {{ toFixedCutZero(token.tokenBalance, 2) }}
                      {{ token.symbol }}
                    </div>
                    <div class="dollar-amt">
                      $
                      {{ toFixedCutZero(token.tokenBalance, 2) }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="actions">
                <div
                  class="d1"
                  @mouseenter="iconHighlight = false"
                  @mouseleave="iconHighlight = true"
                  v-clipboard:success="copSuccess"
                  v-clipboard:copy="account"
                >
                  <img
                    src="../../../assets/image/wallect/headercopy.svg"
                    height="24px"
                    width="24px"
                    v-if="iconHighlight && !themetype"
                  />
                  <img
                    src="../../../assets/image/dark/copy4.svg"
                    height="24px"
                    width="24px"
                    v-else-if="iconHighlight && themetype"
                  />
                  <img
                    src="../../../assets/image/wallect/headercopy2.svg"
                    height="24px"
                    width="24px"
                    v-else
                  />
                  <div v-if="iconHighlight">Copy Address</div>
                  <div v-else style="color: #aa68f0">Copy Address</div>
                </div>
                <div
                  class="d1"
                  @mouseenter="iconHighlight3 = false"
                  @mouseleave="iconHighlight3 = true"
                  @click="outlogin()"
                >
                  <img
                    src="../../../assets/image/wallect/Union.svg"
                    height="24px"
                    width="24px"
                    v-if="iconHighlight3 && !themetype"
                  />
                  <img
                    src="../../../assets/image/dark/Union3.svg"
                    height="24px"
                    width="24px"
                    v-else-if="iconHighlight3 && themetype"
                  />
                  <img
                    src="../../../assets/image/wallect/Union2.svg"
                    height="24px"
                    width="24px"
                    v-else
                  />
                  <div v-if="iconHighlight3">Disconnect</div>
                  <div v-else style="color: #aa68f0">Disconnect</div>
                </div>
              </div>
            </DropdownContent>
          </Dropdown>
        </div>
      </div>

      <div class="line-separator" v-if="account"></div>

      <div v-if="account">
        <div class="TXN-icon-false" @click="HistoryF">
          <img src="../../../assets/image/icon/new/TX.svg" />
        </div>
      </div>

      <div class="line-separator"></div>

      <div class="theme-toggle">
        <div
          class="light-toggle"
          @click="theme()"
          :class="{ selected: showthemicon }"
        >
          <img
            src="../../../assets/image/icon/new/sun2.svg"
            v-if="!showthemicon"
          />
          <img src="../../../assets/image/icon/new/sun.svg" v-else />
        </div>
        <div
          class="dark-toggle"
          @click="theme()"
          :class="{ selected: !showthemicon }"
        >
          <img src="../../../assets/image/icon/new/moon.svg" />
        </div>
      </div>
    </div>
    <WalletConnect v-if="showSignin" :setShow="setShowModal" />
    <SwitchNetwork
      v-if="showSwitchNetwork"
      :setShow="setShowModal"
      :switchChainId="switchId"
    />
    <TestToken v-if="showtoken" :setShow="setShowModal" />
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  connect,
  getScanUrl,
  logout,
  getPoolList,
  getBalance,
  toFixedCutZero,
} from '../../../SDK';
// import { checkTransactionStatus } from '../../../SDK/checkHash';

import Dropdown from '../../../components/new/Dropdown/index.vue';
import Button from '../Btn/index.vue';
import DropdownContent from '../../../components/new/Dropdown/DropdownContent.vue';
import DropdownItem from '../../../components/new/Dropdown/DropdownItem.vue';
import TestToken from '../../../components/new/SignIn/TestToken';
import WalletConnect from '../../../components/new/SignIn/WalletConnect';
import SwitchNetwork from '../../../components/new/SignIn/SwitchNetwork';

// import Switchs from '../../../components/Signin/Switchs';
// import Pcdropdow from '../Signin/Pcdropdow';
// import Mobileindex from './Mobile/Mobileindex';
export default {
  components: {
    Button,
    Dropdown,
    DropdownContent,
    DropdownItem,
    // Modal,
    // Mobileindex,
    // Pcdropdow,
    // Switchs,
    SwitchNetwork,
    TestToken,
    WalletConnect,
  },
  name: 'Navigation',
  computed: {
    ...mapGetters([
      'account',
      'isLogin',
      'networkF',
      'iswidth',
      'providerInfo',
      'chain',
      'chainid',
      'themetype',
      'equipment',
      'TXNtype',
      'borrowInfo',
    ]),
  },
  data() {
    return {
      toFixedCutZero: toFixedCutZero,

      windowWidth: document.documentElement.clientWidth,
      Mobile: false,
      ismoneytype: 3,
      showerrornetwork: true,
      iconHighlight: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight7: true,
      iconHighlight8: true,
      showthemicon: true,
      TXNicon: true,
      defaultChainID: 80001,
      showaccount: false,
      showLeftTokens: false,
      showSignin: false,
      showswitchs: false,
      showswitch: false,
      showSwitchNetwork: false,
      showtoken: false,
      loading: false,
      sintype: true,
      switchId: 0,
      current: [1],
      dropdownbg: '#ffffffcc',
      dropdowncolor: '#ffffff',
      dropdowncolorhover: '#000000',
      networklist: [
        {
          icon: require('../../../assets/image/icon/FTM.svg'),
          name: 'Fantom Testnet',
          id: 4002,
        },
        {
          icon: require('../../../assets/image/icon/AVAX.svg'),
          name: 'Avalanche Testnet',
          id: 43113,
        },
        {
          icon: require('../../../assets/image/icon/BNB.svg'),
          name: 'Bsc Testnet(Root Chain)',
          id: 97,
        },
        {
          icon: require('../../../assets/image/icon/polygon.svg'),
          name: 'Polygon Mumbai',
          id: 80001,
        },
        {
          icon: require('../../../assets/image/icon/Arbitrum.svg'),
          name: 'Arbitrum Testnet',
          id: 421613,
        },
        {
          icon: require('../../../assets/image/icon/ETHicon.svg'),
          name: 'Goerli Testnet',
          id: 5,
        },
      ],
      networklist2: [],
      navMenu: [
        {
          key: 1,
          name: 'Dashboard',
          path: '/Dashboard',
        },
        {
          key: 2,
          name: 'Market',
          path: '/Market',
        },
        {
          key: 3,
          name: 'Liquidations',
          path: '/Liquidations',
        },
        {
          key: 4,
          name: 'Docs',
          path: '',
        },
        // {
        //   key: 5,
        //   name: "Socials",
        //   path: "",
        // },
      ],
      tokenList: [],
      isTokenListLoading: true,
    };
  },
  created() {
    window.addEventListener('scroll', () => {
      if (this.themetype) {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--bodyshadow', `rgba(255, 255, 255, 0.03)`);
      } else {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--bodyshadow', `rgba(0, 0, 0, 0.03)`);
      }
      setTimeout(() => {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--bodyshadow', `none`);
      }, 800);
    });
    this.networklist2 = this.networklist.filter((it) => it.id !== this.chainid);
    let _theme = localStorage.getItem('theme') ?? 'light';
    this.$store.dispatch('user/setthemetype', _theme === 'dark');
    this.showthemicon = _theme === 'light';
    this.themetypeF();
    this.Router();
    let account = localStorage.getItem('account') ?? '';
    let wallet = localStorage.getItem('wallet') ?? '';
    if (account !== '' && wallet !== '') {
      connect(wallet, (res) => {
        this.$store.dispatch('user/setAccount', res.account);
        this.$store.dispatch('user/setChain', res.chain);
        this.$store.dispatch('user/setChainid', res.chainID);
        localStorage.setItem('account', res.account);
      }).then((data) => {
        this.$store.dispatch('user/setAccount', data.account);
        this.$store.dispatch('user/setChain', data.chain);
        this.$store.dispatch('user/setChainid', data.chainID);
        this.$store.dispatch('user/setproviderInfo', data.providerInfo);
        localStorage.setItem('wallet', data.providerInfo.id);
        localStorage.setItem('account', data.account);
      });
    }
  },
  watch: {
    chainid(nes) {
      this.networklist2 = this.networklist.filter((it) => it.id !== nes);
      let chains = this.networklist.find((item) => item.id === nes);
      if (chains) {
        this.showerrornetwork = true;
      } else {
        this.showerrornetwork = false;
        this.switchId = 97;
        this.ismoneytype = 3;
        this.showswitchs = !this.showswitchs;
      }
      this.getWalletBalance();
    },
    $route(to, from) {
      if (to.name !== from.name) {
        this.Router();
      }
    },
    themetype() {
      this.themetypeF();
    },
  },
  mounted() {
    this.closeModal();
  },
  methods: {
    HistoryF() {
      this.$router.push('/Market/History');
      this.$bus.emit('changeInfo', 1);
    },

    themetypeF() {
      if (this.themetype) {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationimgcolor', `#3c3642`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationimg2color', `#b1afb3`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationcolor', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationbg', `#27202E`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdownbg', `#27202E`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolor', `#FFFFFF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolorhover', `#AA68F0`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--loading1', `#140A20`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--loading2', `#1D1426`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popoverbg', `#141414`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovercolor', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovershadow', `#aa68f026`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovershadow2', `3px 3px 7px rgb(0 0 0 / 7%)`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb', `#140A20`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartborder', `#140A20`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb-p1', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb-p2', `#ffffff80`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty(
            '--dropdown-menu',
            `0px 0px 4px rgba(170, 104, 240, 0.2)`
          );
        document
          .getElementsByTagName('body')[0]
          .style.setProperty(
            '--dropdown-menu',
            `0px 0px 4px rgba(170, 104, 240, 0.2)`
          );
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabheadercolor', `#828389`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabheaderactivecolor', `#E9EAF2`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tableheaderbg', `#282A30`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tableheadertext', `#E9EAF2`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabletext', `#D0D2DD`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tablehoverbg', `#393B42`);
      } else {
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationimgcolor', `#f2f2f2`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationimg2color', `#616161`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationcolor', `#000`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--Notificationbg', `#FFFFFF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdownbg', `#ffffffcc`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolor', `#000000`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--dropdowncolorhover', `#AA68F0`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--loading1', `#F9F2FF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--loading2', `#F1E0FF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popoverbg', `#141414`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovercolor', `#00000033`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovershadow', `#DDC6FF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--popovershadow2', `none`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb', `#ffffff`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartborder', `#F0F4F8`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb-p1', `#010101`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--echartgb-p2', `#01010180`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty(
            '--dropdown-menu',
            `0px 0px 4px rgba(170, 104, 240, 0.2) `
          );
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabheadercolor', `#1414144d`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabheaderactivecolor', `#141414`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tableheaderbg', `#FFFFFF`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tableheadertext', `#141414`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tabletext', `#141414`);
        document
          .getElementsByTagName('body')[0]
          .style.setProperty('--tablehoverbg', `#F1EDFF`);
      }
    },
    theme() {
      if (this.showthemicon) {
        this.showthemicon = false;
        this.$store.dispatch('user/setthemetype', true);
        localStorage.setItem('theme', 'dark');
      } else if (this.showthemicon === false) {
        this.showthemicon = true;
        this.$store.dispatch('user/setthemetype', false);
        localStorage.setItem('theme', 'light');
      }
    },
    backloding() {
      window.open('http://cedro.finance/');
    },
    Router() {
      if (this.$route.name === 'Dashboard') {
        this.current = [1];
      } else if (this.$route.name === 'Market') {
        this.current = [2];
      } else if (this.$route.name === 'Liquidations') {
        this.current = [3];
      } else if (this.$route.name === 'MarketDetails') {
        this.current = [2];
      }
    },
    SwitchNetworkF(item) {
      this.switchId = item.id;
      this.showSwitchNetwork = !this.showSwitchNetwork;
    },
    icons(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/FTM.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/AVAX.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../../assets/image/icon/BNB.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/ETHicon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else {
        return require('../../../assets/image/wallect/unknown.webp');
      }
    },
    copSuccess() {
      this.$message.success('copy success');
    },
    outlogin() {
      logout();
      this.$store.dispatch('user/setAccount', '');
      localStorage.setItem('wallet', '');
      localStorage.setItem('account', '');
      this.$store.dispatch('user/setBorroeInfo', null);
    },
    setShowModal(value) {
      this.showSignin = value;
      this.showswitch = value;
      this.showtoken = value;
      this.showswitchs = value;
      this.showSwitchNetwork = value;
    },
    showcountlist() {
      this.showaccount = !this.showaccount;
    },
    topmodel1() {
      this.showaccount = false;
    },
    viewbsSan() {
      let url = getScanUrl(this.account);
      window.open(url);
    },
    closeModal() {
      document.addEventListener('click', (e) => {
        const iconEl = document.querySelector('.right');
        if (iconEl && !iconEl.contains(e.target)) {
          this.showaccount = false;
        }
      });
    },
    async login() {
      this.showSignin = !this.showSignin;
    },
    token() {
      this.showtoken = !this.showtoken;
    },
    handleClick(item) {
      if (item.key !== this.$route.path) {
        console.log('this.$router :>> ', this.$router);
        this.$router.push(item.path);
      }
    },
    openDocs() {
      window.open('https://docs.cedro.finance/');
    },
    async getWalletBalance() {
      let tokenList = await getPoolList();
      // let usdcBalance = 0;
      tokenList = tokenList.filter((item) => item.chainid === this.chainid);
      let list = this.borrowInfo?.info ?? [];

      const balance = await Promise.all(
        tokenList.map(async (item) => {
          const a = await getBalance(item.id);
          let oraclePrice = null;
          for (const obj of list) {
            if (obj.symbol === item.symbol) {
              oraclePrice = obj.price;
              break; // Once found, exit the loop
            }
          }
          return {
            ...item,
            tokenBalance: +a.balance,
            oraclePrice,
          };
        })
      );

      this.tokenList = balance;
      this.isTokenListLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  align-items: center;
  padding: 14px 40px;
  box-shadow: 0px 0px 0px 0px rgba(66, 6, 157, 0.05),
    2px 6px 13px 0px rgba(66, 6, 157, 0.05),
    9px 22px 24px 0px rgba(66, 6, 157, 0.04),
    19px 50px 32px 0px rgba(66, 6, 157, 0.03),
    34px 89px 38px 0px rgba(66, 6, 157, 0.01),
    53px 139px 42px 0px rgba(66, 6, 157, 0);
  position: relative;
  z-index: 10;

  &.top {
    background-color: rgba(255, 255, 255, 0.15);
    border-bottom: 1px solid rgba(20, 20, 20, 0.04);
  }
  &.top-dark {
    background-color: rgba(11, 11, 11, 0.15);
    border-bottom: 1px solid rgba(20, 20, 20, 0.04);
    .nav-menu {
      .nav-item {
        &:hover,
        &.nav-item-active {
          background-color: #0b0b0b66;
        }
      }
    }

    .nav-right {
      .right {
        .dropdown-toggler-btn {
          background-color: #0b0b0b66;
        }

        .dropdown-bg {
          border: 1px solid #393b42;
          background: #282a30;
        }
        .switch-chain-list-item {
          color: #e9eaf2;
        }

        .wallet-info {
          background-color: #0b0b0b66;
          .dropdown-toggler-btn {
            background-color: #bcc1e726;
            color: #ffffff;
          }
          .available-tokens {
            .n1 {
              color: #abacb3;
            }
            .token {
              .token-name {
                color: #d0d2dd;
              }
              .token-balance {
                .token-amt {
                  color: #d0d2dd;
                }
                .dollar-amt {
                  color: #828389;
                }
              }
            }
          }

          .actions {
            div {
              color: #ffffff;
            }
          }
        }
      }
    }

    .TXN-icon-false {
      background-color: #9654dc;
    }
  }

  .nav-logo {
    cursor: pointer;
    margin-right: 20px;
  }
  .nav-menu {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    margin-left: 20px;
    flex-grow: 1;
    gap: 4px;
    li {
      list-style: none;
    }

    .nav-item {
      cursor: pointer;
      color: #ffffff;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
      padding: 8px 16px;
      line-height: 24px;

      &:hover,
      &.nav-item-active {
        background-color: #ffffff4d;
        border-radius: 70px;
      }
    }
  }

  .nav-right {
    display: flex;
    align-items: center;

    .right {
      display: flex;
      align-items: center;
      gap: 12px;

      .dropdown-toggler-btn {
        font-size: 14px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.14px;
        text-align: center;
        display: flex;
        align-items: center;
        gap: 6px;
        padding: 8px;
        border-radius: 48px;
        cursor: pointer;
        border: none;
        transition: all 0.1s linear;
        color: #ffffff;
        background-color: #ffffff4d;
        max-width: 160px;
        div {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: clip;
        }
      }

      .dropdown-bg {
        position: absolute;
        right: 0;
        margin-top: 10px;
        padding: 6px;
        /* width: 200px; */
        transform-origin: top right;
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 0px 0px rgba(173, 165, 217, 0.1),
          1px 6px 12px 0px rgba(173, 165, 217, 0.1),
          4px 22px 23px 0px rgba(173, 165, 217, 0.09),
          9px 50px 31px 0px rgba(173, 165, 217, 0.05),
          17px 89px 36px 0px rgba(173, 165, 217, 0.01),
          26px 139px 40px 0px rgba(173, 165, 217, 0);
        backdrop-filter: blur(27.5px);
        border-radius: 14px;
        z-index: 999;
      }
      .switch-chain-list-item {
        display: flex;
        align-items: center;
        gap: 12px;
        padding: 9px 10px;
        cursor: pointer;
        white-space: nowrap;
        &:hover {
          background-color: #ffffff;
          border-radius: 12px;
        }
      }

      .wallet-info {
        padding: 4px 4px 4px 16px;
        background-color: #ffffff4d;
        border-radius: 999px;
        display: flex;
        align-items: center;
        gap: 10px;
        .wallet-balance {
          color: #ffffff;
          font-weight: 900;
          font-size: 14px;
          letter-spacing: 0.14px;
        }
        .dropdown-toggler-btn {
          font-weight: 900;
          color: #141414;
          background-color: #ffffff;
          padding: 6px 8px;
          gap: 7px;
        }

        .dropdown-bg {
          padding: 20px;
          min-width: 380px;
        }

        .available-tokens {
          padding-bottom: 18px;
          border-bottom: 1px solid rgba(20, 20, 20, 0.04);
          .n1 {
            color: rgba(20, 20, 20, 0.5);
            font-size: 13px;
            font-weight: 700;
            line-height: 100%;
          }
          .token {
            padding: 16px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .token-name {
              display: flex;
              align-items: center;
              gap: 14px;
              font-size: 18px;
              color: #141414;
            }
            .token-balance {
              text-align: right;
              .token-amt {
                color: #141414;
                font-size: 18px;
                font-weight: 500;
                line-height: 24px;
              }
              .dollar-amt {
                color: rgba(20, 20, 20, 0.5);
                font-size: 14px;
                font-weight: 700;
                line-height: 20px;
              }
            }
          }
        }

        .actions {
          padding-top: 18px;
          display: flex;
          flex-direction: column;
          gap: 16px;
          div {
            display: flex;
            align-items: center;
            gap: 8px;
            color: #141414;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            white-space: nowrap;
            cursor: pointer;
          }
        }
      }
    }
  }

  .line-separator {
    content: '';
    display: block;
    width: 1px;
    height: 24px;
    background-color: #ebeaee40;
    margin: 0px 20px;
  }

  .TXN-icon-false {
    background: #aa68f0;
    border-radius: 100%;
  }

  .theme-toggle {
    display: flex;
    align-items: center;
    background-color: #ffffff4d;
    border-radius: 999px;
    padding: 4px;
    gap: 6px;

    .light-toggle,
    .dark-toggle {
      cursor: pointer;
      border-radius: 100%;
      padding: 8px;

      img {
        height: 16px;
        width: 16px;
      }
      &:hover {
        opacity: 0.9;
      }
    }

    .light-toggle {
      &.selected {
        background: #ffffff;
      }
    }

    .dark-toggle {
      &.selected {
        background: #6751ff;
      }
    }
  }
}
</style>
