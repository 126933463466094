<template>
  <div class="modal-overlay" @click.stop="close">
    <div
      class="modal"
      @click.stop
      :style="{ width: modalWidth }"
      :class="{ 'modal-dark': themetype }"
    >
      <div class="close" @click="close" v-if="showCloseBtn">
        <div class="close-icon-wrapper">
          <img src="../../../assets/image/dark/Close_L3.svg" v-if="themetype" />
          <img src="../../../assets/image/icon/Close_L.svg" v-else />
        </div>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'modal',
  props: {
    modalWidth: {
      type: String,
      default: '480px',
    },
    showCloseBtn: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapGetters(['themetype']),
  },
  methods: {
    close() {
      this.$emit('close-modal');
    },
  },
};
</script>

<style scoped lang="scss">
.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #2a25424d;
  backdrop-filter: blur(17px);
  overflow-y: clip;
  z-index: 20;
}
.modal {
  position: relative;
  text-align: center;
  background-color: #ffffffe5;
  padding: 35px 40px;
  border-radius: 20px;
  box-shadow: 0px 0px 0px 0px rgba(119, 110, 145, 0.12),
    0px 9px 19px 0px rgba(119, 110, 145, 0.12),
    0px 34px 34px 0px rgba(119, 110, 145, 0.1),
    0px 77px 46px 0px rgba(119, 110, 145, 0.06),
    0px 138px 55px 0px rgba(119, 110, 145, 0.02),
    0px 215px 60px 0px rgba(119, 110, 145, 0);
  backdrop-filter: blur(27.5px);

  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    .close-icon-wrapper {
      cursor: pointer;
      background: #ffffff;
      position: relative;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 4;
    }
  }

  &.modal-dark {
    background-color: #1a1b1f;
    .close {
      .close-icon-wrapper {
        background: #0b0b0b;
      }
    }
  }
}
</style>
