<template>
  <div>
    <Modal modalWidth="504px" @close-modal="closeModal">
      <div class="switch-network">
        <div class="title">Switch Network</div>
        <div class="subtitle">
          Your current network is {{ chain }} <br />
          Please switch to {{ SwToNetworkName }}
        </div>

        <div class="switch-icon">
          <div class="chain old-chain">
            <img :src="fromPath" class="" height="65px" width="65px" />
            <div class="chain-name">{{ chain }}</div>
          </div>
          <div class="change-icon">
            <img
              src="../../../assets/image/icon/new/directionRight.svg"
              class=""
              height="32px"
              width="32px"
            />
          </div>
          <div class="chain new-chain">
            <img :src="toPath" class="" height="65px" width="65px" />
            <div class="chain-name">{{ SwToNetworkName }}</div>
          </div>
        </div>

        <div class="switch-btn">
          <Button showIcon :handleClick="switchwork"
            >Switch to {{ SwToNetworkName }}</Button
          >
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { chainIdDict } from '../../../SDK/config';
import { changeMetamaskChain } from '../../../SDK';

import Modal from '../../../components/new/Modal/index';
import Button from '../Btn/index';

export default {
  name: 'SwitchNetwork',
  components: {
    Button,
    Modal,
  },
  created() {
    this.SwToNetworkName = chainIdDict[this.switchChainId];
    console.log(
      '🚀 ~ file: SwitchNetwork.vue:56 ~ created ~ switchChainId:',
      this.switchChainId,
      this.SwToNetworkName
    );
    this.fromPath = this.getIcon(this.chain);
    this.toPath = this.getIcon(this.SwToNetworkName);
  },
  computed: {
    ...mapGetters(['chainid', 'chain', 'themetype']),
  },
  data() {
    return {
      btnloading: false,
      toPath: '',
    };
  },
  methods: {
    getIcon(name) {
      if (name === 'Fantom Testnet') {
        return require('../../../assets/image/icon/new/chain/ftm.svg');
      } else if (name === 'Avalanche Testnet') {
        return require('../../../assets/image/icon/new/chain/avax.svg');
      } else if (name === 'Bsc Testnet(Root Chain)') {
        return require('../../../assets/image/icon/new/chain/bsc.svg');
      } else if (name === 'Polygon Mumbai') {
        return require('../../../assets/image/icon/polygon.svg');
      } else if (name === 'Arbitrum Testnet') {
        return require('../../../assets/image/icon/Arbitrum.svg');
      } else if (name === 'Goerli Testnet') {
        return require('../../../assets/image/icon/new/chain/eth.svg');
      } else {
        return require('../../../assets/image/icon/unknown.svg');
      }
    },
    async switchwork() {
      this.btnloading = true;
      await changeMetamaskChain(this.switchChainId);
      this.btnloading = false;
      this.closeModal();
    },
    closeModal() {
      this.setShow(false);
    },
  },
  props: {
    setShow: Function,
    switchChainId: Number,
  },
};
</script>

<style>
.switch-network {
  padding: 15px 0;

  .title {
    font-size: 28px;
    font-weight: 700;
    line-height: normal;
    color: #141414;
  }

  .subtitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
    color: #14141480;
  }

  .switch-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin: 44px 0;

    .chain {
      .chain-name {
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        color: #14141480;
        margin-top: 8px;
      }
    }
  }

  .switch-btn {
    display: flex;
    justify-content: center;
  }
}
</style>
