<template>
  <div>
    <Modal modalWidth="550px" @close-modal="setShow(false)">
      <div class="token" :class="themetype === true ? 'token-dark' : ''">
        <div class="title">Get Test Tokens</div>
        <div class="subtitle">
          <p>
            The assets on a testnet are not “real,” meaning that they cannot be
            withdrawn to your wallet for any real economic value.
          </p>
        </div>
        <a-tabs default-active-key="1" type="card">
          <a-tab-pane key="1">
            <template #tab> Tokens </template>
            <div class="subtitle">
              You can request 1,000 Testnet (Testnet token) once per address
              every day.
            </div>
            <div style="height: 312px; overflow-y: scroll">
              <div
                class="test-token"
                v-for="(item, index) in tokens"
                :key="index"
              >
                <div class="left">
                  <img
                    :src="item.icon"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">{{ item.symbol }}</div>
                      <TokenPopover
                        :itemId="item.id"
                        :symbol="item.symbol"
                        :decimals="item.decimals"
                        :address="textdata.address"
                      />
                    </div>
                    <div class="p1">{{ item.chain }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn1">
                    <Button
                      variant="primary"
                      disabled
                      v-if="item.date1 > 0"
                      size="sm"
                    >
                      Get
                    </Button>
                    <Button
                      variant="primary"
                      v-else
                      :handleClick="() => getToken(item)"
                      :isLoading="item.loading"
                      size="sm"
                    >
                      Get
                    </Button>
                  </div>
                  <div class="btn2">
                    <Button
                      variant="secondary"
                      v-if="showadd"
                      :handleClick="() => addToken(item)"
                      size="sm"
                    >
                      Add To Wallet
                    </Button>
                  </div>
                </div>
              </div>
              <!--        ce-->
              <div
                class="test-token"
                v-for="item in tokens2"
                :key="item.symbol"
              >
                <div class="left">
                  <img
                    :src="item.icon"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">{{ item.symbol }}</div>
                      <TokenPopover
                        :itemId="item.id"
                        :symbol="item.symbol"
                        :decimals="item.decimals"
                        :address="textdata.address"
                      />
                    </div>
                    <div class="p1">{{ item.chain }}</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn1">
                    <Button
                      variant="secondary"
                      v-if="showadd"
                      :handleClick="() => addToken(item)"
                      size="sm"
                    >
                      Add To Wallet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
          <a-tab-pane key="2">
            <template #tab> Gas</template>
            <div class="subtitle"></div>
            <div style="height: 312px; overflow-y: scroll; margin-top: -15px">
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/BNB.svg"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">BNB</div>
                    </div>
                    <div class="p1">Bsc Testnet(Root Chain)</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/FTM.svg"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">FTM</div>
                    </div>
                    <div class="p1">Fantom Test network</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open2()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/AVAX.png"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">AVAX</div>
                    </div>
                    <div class="p1">Avalanche Test network</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open3()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/polygon.svg"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">MATIC</div>
                    </div>
                    <div class="p1">Polygon Test network</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open4()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/Arbitrum.svg"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">AGOR</div>
                    </div>
                    <div class="p1">Arbitrum Goerli Test network</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open5()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
              <div class="test-token">
                <div class="left">
                  <img
                    src="../../../assets/image/icon/ETHicon.svg"
                    width="30px"
                    height="30px"
                    class="token-img"
                    alt=""
                  />
                  <div>
                    <div class="clicktext">
                      <div class="name">AGOR</div>
                    </div>
                    <div class="p1">Goerli Test network</div>
                  </div>
                </div>
                <div class="right">
                  <div class="btn4">
                    <Button variant="primary" :handleClick="() => open6()">
                      Faucet
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </a-tab-pane>
        </a-tabs>
      </div>
    </Modal>
    <SwitchNetwork
      v-if="showSwitchNetwork"
      :setShow="setShowModal"
      :switchChainId="switchId"
    />
  </div>
</template>

<script>
// import Switchs from './Switchs';
import { mapGetters } from 'vuex';
import Modal from '../../../components/new/Modal/index';
import Button from '../../../components/new/Btn/index';
import TokenPopover from '../../../components/new/SignIn/TokenPopover';
import SwitchNetwork from '../../../components/new/SignIn/SwitchNetwork';

import {
  getImportTokenList,
  getPoolList,
  importToken,
  mint,
  updateGetToken,
} from '../../../SDK';
export default {
  name: 'TestToken',
  components: {
    Button,
    Modal,
    SwitchNetwork,
    TokenPopover,
  },
  computed: {
    ...mapGetters(['chainid', 'themetype']),
  },
  props: {
    setShow: Function,
  },
  data() {
    return {
      loading: false,
      loading2: false,
      switchNetwork: '',
      switchId: '',
      showbtnget: false,
      showadd: true,
      showadd2: true,
      showswitch: false,
      showpop1: false,
      showSwitchNetwork: false,
      iconHighlight1: true,
      iconHighlight2: true,
      iconHighlight3: true,
      iconHighlight4: true,
      visible: true,
      btndis: true,
      btndis2: true,
      setTid: 0,
      date1: 0,
      date2: 0,
      bg: '#ffffff',
      textdata1: {
        address: '',
        symbol: '',
        precision: 0,
      },
      textdata: {
        address: '',
        symbol: '',
        precision: 0,
      },
      tokens2: [
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
      ],
      tokens: [
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
        {
          date1: 0,
          loading: false,
          icon: require('../../../assets/image/wallect/ProtocolIcon.svg'),
          chain: 'fantom',
          chainid: 4002,
          decimals: 18,
          id: '0x01BC3E45726bdD57D2E0a3D9ddE0feaA92521B23',
          symbol: 'USDC',
        },
      ],
    };
  },
  mounted() {},
  created() {
    this.themetypeF();
    this.testdata();
    this.testdata2();
  },
  methods: {
    themetypeF() {
      if (this.themetype) {
        this.bg = `#1D1426`;
      } else {
        this.bg = `#ffffff`;
      }
    },
    addToken(item) {
      if (item.chainid !== this.chainid) {
        this.showSwitchNetwork = !this.showSwitchNetwork;
        this.switchNetwork = item.chain;
        this.switchId = item.chainid;
      } else {
        importToken(item);
      }
    },
    testdata() {
      this.tokens = getPoolList().map((item) => {
        let date1 = updateGetToken(item.id, 'get');
        return {
          icon: require(`../../../assets/image/icon/${item.symbol}.svg`),
          loading: false,
          date1: date1,
          ...item,
        };
      });
    },
    testdata2() {
      this.tokens2 = getImportTokenList().map((item) => {
        let date1 = updateGetToken(item.id, 'get');
        let _name = item.symbol;
        if (_name.startsWith('ce')) {
          _name = _name.slice(2);
        }
        if (_name.startsWith('debt')) {
          _name = _name.slice(4);
        }
        return {
          icon: require(`../../../assets/image/icon/${_name}.svg`),
          loading: false,
          date1: date1,
          ...item,
        };
      });
    },
    getToken(item) {
      if (item.chainid !== this.chainid) {
        this.showSwitchNetwork = !this.showSwitchNetwork;
        this.switchNetwork = item.chain;
        this.switchId = item.chainid;
      } else {
        item.loading = true;
        mint(item, (code) => {
          if (code === 1) {
            updateGetToken(item.id, 'set');
            item.date1 = updateGetToken(item.id, 'get');
            item.loading = false;
          } else if (code > 1) {
            item.loading = false;
          }
        });
      }
    },
    open() {
      window.open('https://testnet.bnbchain.org/faucet-smart');
    },
    open2() {
      window.open('https://faucet.fantom.network/ ');
    },
    open3() {
      window.open('https://faucet.avax.network/ ');
    },
    open4() {
      window.open('https://faucet.polygon.technology/');
    },
    open5() {
      window.open('https://faucet.triangleplatform.com/arbitrum/goerli');
    },
    open6() {
      window.open('https://goerlifaucet.com/');
    },
    copSuccess() {
      this.$message.success('copy success');
    },
    setShowModal(value) {
      this.showSwitchNetwork = value;
    },
  },
};
</script>

<style scoped lang="scss">
.token {
  .title {
    font-size: 26px;
    font-weight: 700;
    line-height: 130%;
    color: #141414;
    margin-bottom: 12px;
  }
  .subtitle {
    font-size: 15px;
    font-weight: 500;
    line-height: 100%;
    color: #00000080;
    margin: 0px 25px 44px;
  }
  ::v-deep .ant-tabs-bar {
    border-bottom: none;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;
    margin-bottom: 14px;

    .ant-tabs-nav-container {
      height: fit-content;
    }
    .ant-tabs-tab,
    .ant-tabs-tab-active {
      font-size: 14px;
      font-weight: 900;
      line-height: 100%;
      color: #7a7a8c;
      border: none;
      background-color: transparent;
      padding: 8px 10px;
      height: fit-content;
    }
    .ant-tabs-tab-active {
      color: #fff;
      background: #141414;
      border-radius: 9px;
    }
  }

  .test-token {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 10px;

    .left,
    .right {
      display: flex;
      align-items: center;
      .token-img {
        margin-right: 12px;
      }
      .clicktext {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 15px;
        font-weight: 700;
        line-height: 100%;
        color: #141414;
        margin-bottom: 4px;
      }
      .p1 {
        font-size: 12px;
        font-weight: 700;
        line-height: 120%;
        color: #00000080;
      }
    }
    .right {
      gap: 8px;
    }
  }

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
    cursor: pointer;
  }

  /* Track */
  // ::-webkit-scrollbar-track {
  //   background: #f1f1f1;
  // }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #141414;
    background: #393b42;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #141414ad;
  }

  &.token-dark {
    .title {
      color: #e9eaf2;
    }
    .subtitle {
      color: #abacb3;
    }
    ::v-deep .ant-tabs-bar {
      .ant-tabs-tab,
      .ant-tabs-tab-active {
        color: #7a7a8c;
      }
      .ant-tabs-tab-active {
        color: #e9eaf2;
        background: #6751ff;
      }
    }

    .test-token {
      .left {
        .clicktext {
          color: #e9eaf2;
        }
        .p1 {
          color: #abacb3;
        }
      }
    }
    ::-webkit-scrollbar-thumb {
      background: #393b42;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #393b42ad;
    }
  }
}
</style>
