var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"btn",class:{
    primary: _vm.variant === 'primary',
    secondary: _vm.variant === 'secondary',
    dark: _vm.themetype,
    disabled: _vm.isDisabled || _vm.isLoading,
    sm: _vm.size === 'sm',
    md: _vm.size === 'md',
    lg: _vm.size === 'lg',
  },attrs:{"disabled":_vm.isDisabled || _vm.isLoading},on:{"click":function($event){$event.stopPropagation();return _vm.handleClick.apply(null, arguments)}}},[(_vm.isLoading)?_c('div',{staticClass:"btn-spinner"},[_c('img',{attrs:{"src":require("../../../assets/image/icon/new/spinner.svg")}})]):_vm._e(),_c('span',[_vm._t("default")],2),(_vm.showIcon)?_c('div',{staticClass:"btn-icon"},[(_vm.variant === 'primary' && !_vm.isDisabled)?_c('img',{staticClass:"primary direction-right",attrs:{"src":require("../../../assets/image/icon/new/directionRight-white.svg"),"height":"15px","width":"15px"}}):_vm._e(),(_vm.variant === 'primary' && !_vm.isDisabled)?_c('img',{staticClass:"primary direction-right-hover",attrs:{"src":require("../../../assets/image/icon/new/directionRight.svg"),"height":"15px","width":"15px"}}):_vm._e(),(_vm.variant === 'secondary' && !_vm.isDisabled)?_c('img',{staticClass:"direction-right",attrs:{"src":require("../../../assets/image/icon/new/directionRight.svg"),"height":"15px","width":"15px"}}):_vm._e(),(_vm.isDisabled)?_c('img',{staticClass:"direction-right-disabled",attrs:{"src":require("../../../assets/image/icon/new/directionRight-disabled.svg"),"height":"15px","width":"15px"}}):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }