<template>
  <svg
    width="21"
    height="21"
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="10.5"
      cy="10.5"
      r="10.5"
      fill="var(--Notificationimgcolor)"
      fill-opacity="0.05"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.00093 13.8743L7.12582 14.9992L10.4994 11.6255L13.8743 15.0003L14.9992 13.8755L11.6243 10.5006L15.0001 7.12489L13.8752 6L10.4994 9.37574L7.12489 6.00119L6 7.12609L9.37455 10.5006L6.00093 13.8743Z"
      fill="var(--Notificationimg2color)"
      fill-opacity="0.6"
    />
  </svg>
</template>

<script>
export default {
  name: 'CompletedNotificationclose',
  data() {
    return {};
  },
};
</script>

<style scoped></style>
